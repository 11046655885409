import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { GetJobPostQuery } from '../../graphql-types';
import { MetaData, UserContent } from '../components';
import Button from '../components/Button';

type Props = {
	data: GetJobPostQuery;
};

const Container = styled.div`
	min-height: 85vh;
	font-family: ${(props) => props.theme.font.primary};
`;

const Image = styled.img`
	border-radius: 100%;
	max-height: 200px;
	aspect-ratio: 1;

	object-fit: cover;
`;

const CenterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 4rem;
	margin-top: 4rem;
`;
const PLACEHOLDER = '/images/logo-square.jpg';

const JobPage: React.FC<Props> = (props) => {
	const job = props?.data?.wordpressAcfJob?.acf;

	return (
		<React.Fragment>
			<MetaData
				title={job?.title ?? ''}
				description={job?.short_description ?? ''}
			/>

			<Container className="w-container w-container--padded">
				<CenterContainer>
					<Image src={job?.image?.source_url || PLACEHOLDER} />
				</CenterContainer>

				<h2
					className="articleheader center"
					dangerouslySetInnerHTML={{ __html: job?.title ?? '' }}
				/>

				<div className="w-row">
					<div className="w-col w-col-12">
						<UserContent content={job?.description ?? ''} />
					</div>
				</div>
				<CenterContainer>
					<Button href="mailto:recruitment@townsq.co.uk">Email to apply</Button>
				</CenterContainer>
			</Container>
		</React.Fragment>
	);
};

export const query = graphql`
	query GetJobPost($id: String!) {
		wordpressAcfJob(id: { eq: $id }) {
			id
			acf {
				description
				image {
					source_url
				}
				short_description
				title
			}
		}
	}
`;

export default JobPage;
